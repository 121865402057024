/* eslint-disable max-lines-per-function */
import { track } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";
import { waitForDOM } from "common/utils/ui/wait_for";
import { track as rudderstackTrack } from "common/utils/rudderstack/tracker";
import "./site-jabber-carousel-badge/site-jabber-carousel-badge";
import "./site-jabber-carousel.scss";

let currentPage;

const selectors = {
  section: ".js-sitejabber-section",
  spinner: ".js-sitejabber"
};

const $getDotNavs = () => document.querySelectorAll(".js-stjr-reviews-carousel-pagination__dot");
const $getActiveDotNav = () =>
  document.querySelector(".stjr-reviews-carousel-pagination__dot--active");
const $getLeftNav = () => document.querySelector(".js-stjr-reviews-carousel-nav--left");
const $getRightNav = () => document.querySelector(".js-stjr-reviews-carousel-nav--right");

const getSitejabberSection = () => document.querySelector(selectors.section);

const updateCurrentPage = () => {
  const pageData = $getActiveDotNav()?.getAttribute("data-page");
  currentPage = pageData ? parseInt(pageData, 10) + 1 : null;
};

const trackNav = action => {
  track("footer_sitejabber_carousel_pagination_clicked", {
    action,
    page_number: currentPage
  });
  rudderstackTrack("Navigation Clicked", {
    destination: currentPage,
    is_app: Boolean(window.MOBILE_APP),
    location: "site_jabber_carousel",
    platform: window.MOBILE_APP_PLATFORM || "web",
    text: action?.trim(),
    type: "link",
    url: window.location.href,
    nav_type: "carousel_nav"
  });
  updateCurrentPage();
};

const initJabberNavTracking = () => {
  updateCurrentPage();

  $getDotNavs().forEach($dotNav => {
    $dotNav.addEventListener("click", () => {
      trackNav("carousel_nav_clicked");
    });
  });
  $getLeftNav().addEventListener("click", () => {
    trackNav("carousel_nav_left_clicked");
  });
  $getRightNav().addEventListener("click", () => {
    trackNav("carousel_nav_right_clicked");
  });
};

const initJabberReadMoreTracking = () => {
  const callback = ({ target: $target }) => {
    track("footer_sitejabber_review_read_more_clicked", {
      url: $target.href
    });
  };

  onEvent("click", ".stjr-review__read_more_link", callback, true);
};

const initJabberWidgets = () => {
  let widgetClasses = [];
  let isWidgetLoaded = false;

  Array.from(document.querySelectorAll('[class*="stjr-"]')).forEach($node => {
    widgetClasses = [
      ...widgetClasses,
      ...Array.from($node.classList).filter(className => /^stjr-/.test(className))
    ];
  });

  const uniqueWidgetClasses = [...new Set(widgetClasses)];
  const $script = document.createElement("script");
  const search = uniqueWidgetClasses.length
    ? `?widget-classes=${uniqueWidgetClasses.join("|")}`
    : "?widget-classes=stjr-base";

  $script.id = "sj-widget";
  $script.src = `https://www.sitejabber.com/js/v2/6192bd52d45b8/widgets.js${search}`;
  // eslint-disable-next-line no-multi-assign
  $script.onload = $script.onreadystatechange = () => {
    if (!isWidgetLoaded) {
      isWidgetLoaded = true;
      waitForDOM(".stjr-reviews-carousel-pagination__dot--active", initJabberNavTracking);
      waitForDOM(".stjr-review__read_more_link", initJabberReadMoreTracking);
    }
  };

  document.body.appendChild($script);
};

let timerId = null;
const handleScroll = () => {
  if (timerId) return;

  const $sitejabberSection = getSitejabberSection();
  const { scrollTop } = document.documentElement;
  const clientHeight = window.innerHeight;
  const targetTop = $sitejabberSection.offsetTop;

  if (targetTop - scrollTop <= clientHeight * 2.5) {
    window.removeEventListener("scroll", handleScroll, { passive: true });
    $sitejabberSection.querySelector(selectors.spinner)?.classList?.remove("hidden");
    initJabberWidgets();
  }

  timerId = setTimeout(() => {
    timerId = null;
  }, 100);
};

export default () => {
  const $sitejabberSection = getSitejabberSection();
  if (!$sitejabberSection) return;

  handleScroll();
  window.addEventListener("scroll", handleScroll, { passive: true });
};
