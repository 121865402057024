import { initDropdownNavigation } from "common/jiffy/accessibility";
import { initDekstopNavigation as initTransfersNavigation } from "components/masthead/transfers_navigation/component";
import { initDekstopNavigation as initMadeNavigation } from "components/masthead/made_navigation/component";
import initStickersNavigation from "components/masthead/stickers_navigation/component";
import initShirtsNavigation from "components/masthead/shirts_navigation/component";
import "./masthead-subsite-navigation.scss";

const modifiers = {
  open: "masthead-subsite-navigation__content--open"
};

const selectors = {
  content: ".js-subsite-navigation-content",
  trigger: ".js-subsite-navigation-trigger"
};

export default () => {
  initDropdownNavigation({
    openClass: modifiers.open,
    triggerClass: selectors.trigger
  });
  initTransfersNavigation();
  initStickersNavigation();
  initShirtsNavigation();
  initMadeNavigation();
};
