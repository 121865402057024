import { on as onEvent } from "common/utils/ui/event";
import "./component.scss";

const CLASS_NAMES = {
  SECTION_LINK: ".mobile-menu__section",
  DESKTOP: {
    ACTIVE_LINK: "masthead-made-navigation__section--active",
    HIDDEN_CONTENT: "hidden"
  },
  MOBILE: {
    SLIDE: ".js-masthead-made-slide",
    SLIDE_LINK: ".js-masthead-made-slide-link",
    EXPANDED_SLIDE: "masthead-made-navigation__slide--expanded",
    BACK: '.js-mobile-submenu-trigger[data-direction="back"]'
  }
};

const $getContainer = () => document.querySelector(".js-masthead-made-navigation");
const $getRootNavigation = () => document.querySelectorAll(".js-masthead-subsite-navigation");
const $getNavigationLinks = () => document.querySelectorAll(".js-masthead-made-navigation-link");
const $getNavigationContent = () =>
  document.querySelectorAll(".js-masthead-made-navigation-content");

const selectCategory = category => {
  const $activeCategory = category || Array.from($getNavigationContent())[0]?.dataset?.category;

  $getNavigationLinks().forEach(navLink => {
    navLink.classList.toggle(
      CLASS_NAMES.DESKTOP.ACTIVE_LINK,
      navLink.dataset.category === $activeCategory
    );
  });

  $getNavigationContent().forEach($content => {
    $content.classList.toggle(
      CLASS_NAMES.DESKTOP.HIDDEN_CONTENT,
      $content.dataset.category !== $activeCategory
    );
  });
};

export const initDekstopNavigation = () => {
  $getNavigationLinks().forEach($link => {
    const listener = event => {
      const $activeLink = event.target;
      const $activeCategory = $activeLink.dataset.category;
      selectCategory($activeCategory);
    };

    $link.addEventListener("click", listener);
    $link.addEventListener("mouseover", listener);
  });

  $getRootNavigation().forEach($link => {
    $link.addEventListener("mouseleave", event => {
      const $activeLink = event.target;
      const $activeCategory = $activeLink.dataset.category;

      if ($activeCategory === "made") {
        selectCategory();
      }
    });
  });
};

export const initMobileNavigation = () => {
  onEvent("click", CLASS_NAMES.MOBILE.SLIDE_LINK, event => {
    event.preventDefault();
    const $slide = event.target.closest(CLASS_NAMES.MOBILE.SLIDE);

    if ($slide) {
      $slide.classList.toggle(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);

      const $container = $getContainer();
      const $section = $container?.closest(CLASS_NAMES.SECTION_LINK);

      if ($container && $section) {
        const { height } = $container.getBoundingClientRect();
        const minHeight = $slide.classList.contains(CLASS_NAMES.MOBILE.EXPANDED_SLIDE) ? height : 0;
        $section.style.minHeight = `${minHeight}px`;
      }
    }
  });

  onEvent("click", CLASS_NAMES.MOBILE.BACK, event => {
    const $backButton = event.target.closest(CLASS_NAMES.MOBILE.BACK);
    const $currentLevel = parseInt($backButton.dataset.level, 10);
    const $currentSlide = $backButton.closest(".js-masthead-made-slide");

    if ($currentLevel === 3) {
      const $parentSlide = $currentSlide.parentElement.closest(".js-masthead-made-slide");
      $currentSlide.classList.remove(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);
      $parentSlide?.classList.add(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);
    } else if ($currentLevel === 2) {
      $currentSlide.classList.remove(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);
    }

    const $section = $backButton.closest(CLASS_NAMES.SECTION_LINK);
    if ($section) {
      const $container = $getContainer();
      if ($container) {
        const { height } = $container.getBoundingClientRect();
        $section.style.minHeight = `${height}px`;
      }
    }
  });
};
