/* eslint-disable max-params */
import "./products-reviews.scss";
import "./review-item/review-item";
import { throttle } from "../../../common/utils/helpers/delay";

const SELECTORS = {
  container: "js-products-reviews__slider",
  arrows: "js-products-reviews__arrow",
  list: "js-products-reviews__reviews-list",
  listItem: "js-products-reviews__review-item",
  reviewContent: "review-item__review-content",
  reviewText: "js-review-item__review-text",
  readMoreButton: "js-review-item__review-readmore-button",
  readMoreGradient: "js-review-item__review-readmore-gradient"
};

const initSliderControls = $container => {
  const $arrows = $container.querySelectorAll(`.${SELECTORS.arrows}`);
  if ($arrows.length === 0) return;

  const $list = $container.querySelector(`.${SELECTORS.list}`);
  if (!$list) return;

  const $items = $list.querySelectorAll(`.${SELECTORS.listItem}`);
  if ($items.length <= 2) return;

  willRenderSliderArrows($list, $container);

  Array.from($arrows).forEach($arrow => {
    $arrow.addEventListener("click", () => handleArrowClick($list, $arrow));
  });

  const throttledResize = throttle(() => handleDocumentResize($list, $items), 200);
  throttledResize();
  window.addEventListener("resize", () => throttledResize());

  const throttledScroll = throttle(() => handleSliderScroll($list, $arrows), 200);
  throttledScroll();
  $list.addEventListener("scroll", () => throttledScroll(), {
    passive: true
  });

  supportReadMore($list);
};

const handleArrowClick = ($list, $arrow) => {
  $list.currentItem = $list.currentItem || 0;

  let targetItem;
  if ($arrow.dataset.direction === "left") {
    targetItem = $list.currentItem - $list.visibleSegments;
  } else if ($arrow.dataset.direction === "right") {
    targetItem = $list.currentItem + $list.visibleSegments;
  } else {
    return;
  }

  scrollToItem($list, targetItem);
};

const scrollToItem = ($list, targetItem) => {
  const targetLeft = targetItem * ($list.segmentWidth + $list.segmentsGap);

  $list.scroll({
    behavior: "smooth",
    left: targetLeft
  });

  $list.currentItem = targetItem;
};

const willRenderSliderArrows = ($list, $container) => {
  const { offsetWidth, scrollWidth } = $list;
  const $arrows = $container.querySelectorAll(`.${SELECTORS.arrows}`);
  if ($arrows.length === 0) return;

  if (scrollWidth > offsetWidth) {
    Array.from($arrows).forEach($control => {
      $control.classList.remove("invisible");
    });
  }
};

const handleSliderScroll = ($list, $arrows) => {
  const { offsetWidth, scrollLeft, scrollWidth } = $list;
  const [$arrowLeft, $arrowRight] = $arrows;
  const isArrowLeftDisabled = $arrowLeft.hasAttribute("disabled");
  const isArrowRightDisabled = $arrowRight.hasAttribute("disabled");

  // slider is at the leftmost position
  if (scrollLeft === 0) {
    !isArrowLeftDisabled && $arrowLeft.setAttribute("disabled", true);
  } else {
    isArrowLeftDisabled && $arrowLeft.removeAttribute("disabled");
  }

  // slider is at the rightmost position
  if (scrollWidth <= offsetWidth + scrollLeft) {
    !isArrowRightDisabled && $arrowRight.setAttribute("disabled", true);
  } else {
    isArrowRightDisabled && $arrowRight.removeAttribute("disabled");
  }
};

const handleDocumentResize = ($list, $items) => {
  $list.segmentWidth = $items[0].offsetWidth;
  $list.visibleSegments = Math.round($list.offsetWidth / $list.segmentWidth);
  $list.segmentsGap = $items[1].offsetLeft - $items[0].offsetLeft - $list.segmentWidth;

  scrollToItem($list, $list.currentItem);
};

const supportReadMore = $list => {
  const readMoreButtonOnClick = (event, $text, $gradient, height) => {
    event.preventDefault();

    const isVisible = $text.longTextVisible || false;
    $text.longTextVisible = !isVisible;
    $gradient.style.opacity = isVisible ? 1 : 0;
    event.target.textContent = isVisible
      ? event.target.dataset.showText
      : event.target.dataset.hideText;
    $text.style.height = `${isVisible ? height : $text.originalHeight}px`;
  };

  const reviewContexts = Array.from($list.querySelectorAll(`.${SELECTORS.reviewContent}`));
  reviewContexts.forEach($context => {
    const $text = $context.querySelector(`.${SELECTORS.reviewText}`);
    const height = $context.dataset.maxHeight;
    if ($text.offsetHeight <= height) return;

    $text.originalHeight = $text.offsetHeight;
    $text.style.height = `${height}px`;

    const $gradient = $context.querySelector(`.${SELECTORS.readMoreGradient}`);
    $gradient.style.opacity = 1;

    const $button = $context.querySelector(`.${SELECTORS.readMoreButton}`);
    $button.classList.remove("hide");

    $button.addEventListener("click", e => readMoreButtonOnClick(e, $text, $gradient, height));
  });
};

export default () => {
  const $container = document.querySelector(`.${SELECTORS.container}`);
  if (!$container) return;

  initSliderControls($container);
};
