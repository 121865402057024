import "./component.scss";
import { request } from "jiffy-api";
import { track } from "jiffy-analytics";
import { megabitsPerSecond } from "common/utils/helpers/upload-rate";
import routes from "config/routes";
import base64ImageToBlob from "common/utils/media/base64-image-to-blob";
import IFrameMessenger from "common/utils/iframe-messenger";

const iframeSelector = ".js-borneo-full-page-iframe";
const loadingMessageSelector = ".js-borneo-full-loading-message";

const $getIframe = () => document.querySelector(iframeSelector);
const $getLoadingMessage = () => document.querySelector(loadingMessageSelector);

const trackUploads = (event, file, payload = {}) => {
  const data = {
    ...payload,
    page: "borneo",
    type: "borneo",
    file_name: file.name,
    file_type: file.type,
    file_size: file.size,
    dpi: 300
  };
  track(event, data);
};

const handleFileUpload = async (file, body, projectId) => {
  const startTime = performance.now();

  try {
    const headers = { "Content-Type": undefined };
    const response = await request(routes.dtfUploadFromBorneoPath(), { body, headers }, "POST");

    if (response.redirect_url) {
      const endTime = performance.now();
      const transferRateMbps = megabitsPerSecond(file.size, startTime, endTime);

      trackUploads("image_upload_completed", file, {
        upload_speed_megabits_per_second: transferRateMbps.toFixed(2),
        artwork_id: response.artwork_id,
        project_id: response.project_id
      });

      window.location.href = response.redirect_url;
    }
  } catch (error) {
    track("image_upload_error", file, {
      project_id: projectId,
      error_message: error.message,
      error_type: error?.error_type || "image_upload_error"
    });
  }
};

const prepareFormData = ({ file, data, projectId, productId }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("metadata", data.metadata);
  formData.append("is_from_borneo", "true");
  formData.append("generate_link", true);
  formData.append("project_id", projectId);
  formData.append("product_id", productId);
  return formData;
};

const createFileFromBlob = blob => new File([blob], "borneo-artwork.png", { type: "image/png" });

const uploadImageToGong = ({ data, blob, projectId, productId }) => {
  const file = createFileFromBlob(blob);
  const body = prepareFormData({ file, data, projectId, productId });

  trackUploads("image_upload_started", file, { project_id: projectId });

  handleFileUpload(file, body, projectId);
};

/* eslint-disable max-lines-per-function */
const subscribeMessagesFromIframe = event => {
  const $iframe = $getIframe();
  const $loadingMessage = $getLoadingMessage();
  const projectId = $iframe.dataset.projectId || "0";
  const { productId } = $iframe.dataset;

  if (!$iframe || !$loadingMessage) return;

  if (event.data.message === "Project Saved" && event.data.projectId) {
    $iframe.dataset.projectId = event.data.projectId;
  }

  // todo remove this after blob is fully implemented
  if (event.data.message === "Image Base64 Url" && event.data.url !== undefined) {
    $iframe.classList.add("hidden");
    $loadingMessage.classList.remove("hidden");

    base64ImageToBlob(event.data.url, blob => {
      uploadImageToGong({
        data: event.data,
        blob,
        projectId,
        productId
      });
    });
  }

  if (event.data.message === "Image Blob" && event.data.file !== undefined) {
    $iframe.classList.add("hidden");
    $loadingMessage.classList.remove("hidden");

    uploadImageToGong({
      data: event.data,
      blob: event.data.file,
      projectId,
      productId
    });
  }

  if (event.data.message === "urlChanged" && event.data.newPath) {
    const currentPath = window.location.pathname.replace("/borneo", "");
    if (event.data.newPath === currentPath) return;

    window.history.pushState(null, null, `/borneo${event.data.newPath}`);
  }

  if (event.data.message === "focusIframe") {
    $iframe.contentWindow.focus();
  }
};

const init = () => {
  window.mixpanel?.time_event("borneo_page_visit");

  const iframeMessenger = new IFrameMessenger(iframeSelector);
  iframeMessenger.subscribe(subscribeMessagesFromIframe);
};

export default init;
