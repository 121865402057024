import { tryParse } from "common/utils/json";
import { track as rudderstackTrack, extractTextFromLink } from "common/utils/rudderstack/tracker";
import { track } from "./tracker";

const $getDtfProductDetails = () => document.getElementById("vue-product-details-dtf");

const trackedDtfProductProps = () => {
  const $details = $getDtfProductDetails();
  if (!$details) return {};

  // Convert dataset keys to snake_case
  return Object.fromEntries(
    Object.entries($details.dataset).map(([key, value]) => [
      key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase(),
      value
    ])
  );
};

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line import/prefer-default-export
export const initMixpanelEvents = (
  containerSelector = "body",
  elementSelector = "[data-mixpanel-event][data-mixpanel-listen]"
) => {
  document.querySelectorAll(`${containerSelector} ${elementSelector}`).forEach($el => {
    const { mixpanelLoaded, mixpanelListen, mixpanelEvent, mixpanelData } = $el.dataset;
    if (mixpanelLoaded === "true") return;
    $el.dataset.mixpanelLoaded = true;

    const eventTypes = mixpanelListen.split("|");
    const trackingNames = mixpanelEvent.split("|");
    const trackingData = mixpanelData?.split("|") || [];

    eventTypes.forEach((type, index) => {
      const name = trackingNames[index] || trackingNames[0];
      const originalData = trackingData[index] || trackingData[0];

      let eventData = originalData ? tryParse(originalData) : {};
      if (name.startsWith("pdp_video_")) {
        eventData = { ...eventData, ...trackedDtfProductProps() };
      }

      $el.addEventListener(type, () => {
        track(name, eventData);
        if (
          name.includes("cta") &&
          $el.classList.contains("js-jiffy-promotion") &&
          type === "click"
        ) {
          rudderstackTrack("Promotion Clicked", {
            destination: $el.href,
            location: eventData?.page || window.location.href,
            platform: window.MOBILE_APP_PLATFORM || "web",
            is_app: Boolean(window.MOBILE_APP),
            text: extractTextFromLink($el),
            type: "banner",
            url: window.location.href,
            business_line_promo_type: eventData?.business_line_promo_type,
            promotion_name: eventData?.promotion_name
          });
        } else if (name.includes("cta") && type === "click") {
          rudderstackTrack("CTA Clicked", {
            destination: eventData?.target || $el.href,
            is_app: Boolean(window.MOBILE_APP),
            location: eventData?.page || window.location.href,
            platform: window.MOBILE_APP_PLATFORM || "web",
            text: extractTextFromLink($el),
            type: eventData?.type || "button",
            url: window.location.href,
            business_line_promo_type: eventData?.business_line_promo_type,
            promotion_name: eventData?.promotion_name
          });
        }
        if (name.includes("_nav_") && type === "click" && name !== "top_nav_desktop_clicked") {
          rudderstackTrack("Navigation Clicked", {
            destination: $el.href,
            is_app: Boolean(window.MOBILE_APP),
            location: eventData?.page || name,
            platform: window.MOBILE_APP_PLATFORM || "web",
            text: extractTextFromLink($el),
            type: "link",
            url: window.location.href,
            nav_type: eventData?.nav_type || name
          });
        }
      });
    });
  });
};
