/* eslint-disable max-lines-per-function, max-params, no-promise-executor-return */
export const throttle = (callback, wait, immediate = false, context = null) => {
  let timeout = null;
  let initialCall = true;

  return (...args) => {
    const callNow = immediate && initialCall;

    const next = () => {
      callback.apply(context, args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const until = (condition, interval = 100, timeout = 3000) => {
  const startTime = new Date().getTime();

  return new Promise((resolve, _reject) => {
    const checkCondition = () => {
      const currentTime = new Date().getTime();
      if (condition()) {
        resolve(true);
      } else if (currentTime - startTime >= timeout) {
        resolve(false);
      } else {
        setTimeout(checkCondition, interval);
      }
    };

    checkCondition();
  });
};
