import { EventBus, Events } from "jiffy-store";
import { isFunction } from "common/utils/helpers/is";
import { applyModalNavigation } from "common/jiffy/accessibility";
import { track } from "jiffy-analytics";
import { trackEvent } from "./product-color-picker-tracker";

export default class ProductColorPickerModalClass {
  constructor(
    modal,
    options = {
      modalBodySelector: "",
      spinnerSelector: "",
      productsSlidesSelector: "",
      scrollWrapperSelector: "",
      staticContentSelector: "",
      getTrackElements: () => ({})
    }
  ) {
    this.$modal = document.querySelector(modal);
    this.$modalBody = this.$modal?.querySelector(options.modalBodySelector);
    this.$spinner = this.$modal?.querySelector(options.spinnerSelector);
    this.$scrollWrapperSelector = this.$modal?.querySelector(options.scrollWrapperSelector);
    this.options = options;
    this.windowPosition = 0;

    // initialize modal plugin upon modal creation
    this.$$modal = $(this.$modal).modal({ show: false, ...options });

    this.$$modal
      .on("show.bs.modal", this.openModalCallback.bind(this))
      .on("hidden.bs.modal", this.closeModalCallback.bind(this));
  }

  fillContent(html) {
    this.$modalBody.innerHTML = "";
    this.$modalBody.appendChild(html);
    this.$modalBody.classList.remove("hidden");

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  handleScroll() {
    EventBus.publish(Events.COLOR_PICKER_SCROLLED);
  }

  openModalCallback() {
    this.windowPosition = document.documentElement.scrollTop || document.body.scrollTop;
    document.documentElement.classList.add("modal-open-html");
    EventBus.publish(Events.COLOR_PICKER_OPENED);
    track("color_picker_modal_open");
    this.$scrollWrapperSelector.addEventListener("scroll", this.handleScroll.bind(this));

    return this;
  }

  closeModalCallback() {
    document.documentElement.classList.remove("modal-open-html");
    document.documentElement.scrollTop = this.windowPosition;
    EventBus.publish(Events.COLOR_PICKER_CLOSED);
    this.$scrollWrapperSelector.removeEventListener("scroll", this.handleScroll.bind(this));

    return this;
  }

  openModal(callback) {
    this.$$modal.modal("show");
    return isFunction(callback) ? callback() : this;
  }

  getCompositions() {
    return Array.from(this.$modalBody.querySelectorAll(this.options.productsSlidesSelector)).map(
      $el => JSON.parse($el.dataset.mixpanel) || "{}"
    );
  }

  sendOpenMixpanelEvent() {
    trackEvent("color_change_modal_opened", this.options.getTrackElements(), {
      product_compositions: this.getCompositions()
    });

    return this;
  }

  sendCloseMixpanelEvent() {
    trackEvent("color_change_modal_closed", this.options.getTrackElements(), {
      product_compositions: this.getCompositions()
    });

    return this;
  }

  showSpinner() {
    this.$spinner.classList.remove("hidden");

    return this;
  }

  hideSpinner() {
    this.$spinner.classList.add("hidden");

    return this;
  }

  updateAccessibility(isOpen) {
    this.$modalBody.ariaBusy = isOpen;
    if (isOpen) this.$modal.querySelector("button")?.focus();
    applyModalNavigation(this.$modal);

    return this;
  }
}
