/* eslint-disable import/prefer-default-export */
import { submitDtfEvent } from "common/utils/mixpanel/submit-dtf-events";
import { trackRudderstackImageUploadError } from "common/utils/rudderstack/tracker";

/**
 * Checks if the file type is supported for printing.
 *
 * @param {File} file - The file to be checked.
 * @param {boolean} printAsIs - Indicates whether to print the file as is (gangsheet) or not (artwork).
 * @returns {boolean} - Returns true if the file type is supported, otherwise false.
 */
export const isFileTypeSupported = (file, printAsIs) => {
  const fileChecker = printAsIs ? gangsheetFileChecker : artworkFileChecker;

  return fileChecker(file);
};
/* eslint-enable import/prefer-default-export */

const artworkFileChecker = ({ type }) => {
  const valid = type.startsWith("image/") || type === "application/pdf";
  const message = valid ? "" : "You can't upload files of this type";

  return { valid, message };
};

const gangsheetFileChecker = ({ type }) => {
  const valid = type === "image/png";
  const message = valid ? "" : "PNG file is required for gang sheet orders.";

  return { valid, message };
};
/* eslint-enable import/prefer-default-export */

/**
 * Returns the accepted file types based on the given printAsIs flag.
 *
 * @param {boolean} printAsIs - Indicates whether to print the file as is (gangsheet) or not (artwork).
 * @returns {string} - The accepted file types.
 */
export const acceptedFileTypes = printAsIs => (printAsIs ? "image/png" : "image/*,.pdf");

export const logUnsupportedFileTypeEvent = (file, otherData, errorMessage) => {
  submitDtfEvent("image_upload_unsupported_file", {
    file_name: file.name,
    file_type: file.type,
    file_size: file.size,
    ...otherData
  });
  trackRudderstackImageUploadError({
    error_message: errorMessage,
    file_name: file.name,
    file_type: file.type,
    file_size: file.size,
    ...otherData
  });
};
