/* eslint-disable max-lines-per-function, max-params, max-lines */
import { EventBus, Events } from "jiffy-store";
import { sendDataLayer, sendDataLayerOnLinkClickEvent } from "jiffy-analytics";
import initProductsPrimaryMaterialFilter from "components/jiffy/products-primary-material-filter/products-primary-material-filter";
import initProductCards from "components/jiffy/product-card/product-card";
import {
  loadVisibleCompositionEstimates,
  loadFirstFewRowsEstimates
} from "components/jiffy/products/product-composition-estimates";

/** DAM components */
import digitalAssetsImagesPreview from "components/jiffy/digital-assets/digital-assets-images-preview/digital-assets-images-preview";
import digitalAssetsAssetCard from "components/jiffy/digital-assets/digital-assets-asset-card/digital-assets-asset-card";
import "components/jiffy/digital-assets/digital-assets-price/digital-assets-price";
import "components/jiffy/digital-assets/digital-assets-label/digital-assets-label";
import "components/jiffy/digital-assets/digital-assets-rating/digital-assets-rating";
import "components/jiffy/digital-assets/digital-assets-logo/digital-assets-logo";
import "components/jiffy/digital-assets/digital-assets-previews-section/digital-assets-previews-section";

import "components/jiffy/product-card-badge/product-card-badge";
import "components/jiffy/products-shipping-information/products-shipping-information";
import initProductMosaic from "components/jiffy/product-mosaic/product-mosaic";
import initProductsReviews from "components/jiffy/products-reviews/products-reviews";
import "components/jiffy/stars/stars";
import initColorPicker, {
  listenForColorSelect
} from "components/jiffy/product-color-picker/product-color-picker";
import {
  $getBackToResultLink,
  initReferFromProductDetails
} from "components/jiffy/product-details/product-details-back/product-details-back";
import { isMobile, isIE, isSafari } from "common/utils/browser";
import "components/jiffy/fast-delivery-information/fast-delivery-information";
import "./products.scss";
import initTopFilter from "components/jiffy/products-top-filters/products-top-filters";
import mobileApp from "common/utils/mobile/app";
import throttle from "lodash.throttle";
import initProductsMobileCarousel from "./products-mobile-carousel";
import initDtfCategoryFilter from "./dtf-category-filter";
import "components/jiffy/products-dtf-banner/products-dtf-banner";
import "components/jiffy/products-list-seo-text/products-list-seo-text";

const SELECTORS = {
  PRODUCTS_LIST: ".js-products-list",
  GRID: ".js-products-scroll-grid",
  MOBILE_GRID: ".js-products-mobile-scroll-grid",
  ARROW_LEFT: ".js-scroll-left",
  ARROW_RIGHT: ".js-scroll-right"
};

const HIDDEN_CLASS = "hidden";

const $getProductBrands = () => document.querySelector(".js-products-brands");

const initScroll = () => {
  if (isMobile() && !$getProductBrands()) {
    return;
  }

  const $backToResultProduct = $getBackToResultLink();
  const lists = document.querySelectorAll(SELECTORS.PRODUCTS_LIST);
  let isXLDesktop = window.innerWidth >= 1280;
  let hasAnimation = false;

  const scroll = (grid, left, callback) => {
    if (hasAnimation) return;
    hasAnimation = true;

    // This is a workaround for Safari smooth scroll issue
    // https://developer.apple.com/forums/thread/703294
    // Once the issue is fixed, please, revert this commit
    // JIRA LINK: https://dekeo.atlassian.net/browse/DIAMOND-300
    const computedGridStyle = window.getComputedStyle(grid);
    let safariScrollFixApplied = false;

    if (isSafari() && computedGridStyle.overflowX === "hidden") {
      safariScrollFixApplied = true;
      grid.style.overflowX = "auto";
    }

    window.requestAnimationFrame(() => {
      grid.scroll({ left, behavior: "smooth" });
    });

    setTimeout(() => {
      if (isSafari() && safariScrollFixApplied) grid.style.overflowX = "hidden";
      hasAnimation = false;
      callback();
    }, 600);
  };

  const controlsVisibility = (grid, left, leftArrow, rightArrow) => {
    leftArrow.classList.toggle(HIDDEN_CLASS, left <= 0);
    rightArrow.classList.toggle(HIDDEN_CLASS, left + grid.clientWidth >= grid.scrollWidth);
  };

  const scrollLeft = (grid, leftArrow, rightArrow) => {
    let left = grid.scrollLeft - grid.clientWidth;
    if (isIE() && left % 2 === 0) {
      left += 1; // Fix wrong position carousel because of negative margin in carousel
    }
    scroll(grid, left, () => {
      controlsVisibility(grid, left, leftArrow, rightArrow);
    });
  };

  const scrollRight = (grid, leftArrow, rightArrow) => {
    let left = grid.scrollLeft + grid.clientWidth;
    if (isIE() && left % 2 === 0) {
      left -= 1; // Fix wrong position carousel because of negative margin in carousel
    }
    scroll(grid, left, () => {
      controlsVisibility(grid, left, leftArrow, rightArrow);
      loadVisibleCompositionEstimates();
    });
  };

  const scrollToProduct = product => {
    const list = product.closest(SELECTORS.PRODUCTS_LIST);

    if (!list) return;

    const grid = list.querySelector(SELECTORS.GRID);
    const leftArrow = list.querySelector(SELECTORS.ARROW_LEFT);
    const rightArrow = list.querySelector(SELECTORS.ARROW_RIGHT);
    const rowCount = isXLDesktop ? 5 : 4;
    const productIdx = parseInt(product.parentNode.dataset.gaPosition, 10) - 1;
    const left = grid.clientWidth * Math.floor(productIdx / rowCount);

    // Fix Chrome scroll position
    grid.style.margin = 0;
    setTimeout(() => {
      grid.removeAttribute("style");

      scroll(grid, left, () => {
        controlsVisibility(grid, left, leftArrow, rightArrow);
      });
    }, 0);
  };

  const reset = () => {
    lists.forEach(list => {
      const grid = list.querySelector(SELECTORS.GRID);
      const leftArrow = list.querySelector(SELECTORS.ARROW_LEFT);
      const rightArrow = list.querySelector(SELECTORS.ARROW_RIGHT);
      const isScrollVisible = grid.scrollWidth > grid.clientWidth;

      grid.scroll({ left: 0 });

      leftArrow.classList.add(HIDDEN_CLASS);
      rightArrow.classList.toggle(HIDDEN_CLASS, !isScrollVisible);
    });
  };

  lists.forEach(list => {
    const grid = list.querySelector(SELECTORS.GRID);
    const leftArrow = list.querySelector(SELECTORS.ARROW_LEFT);
    const rightArrow = list.querySelector(SELECTORS.ARROW_RIGHT);

    leftArrow.addEventListener("click", () => scrollLeft(grid, leftArrow, rightArrow));
    rightArrow.addEventListener("click", () => scrollRight(grid, leftArrow, rightArrow));
  });

  // If we go trough breakpoint - reset the scroll to zero
  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) return;
    if (window.innerWidth >= 1280 && !isXLDesktop) {
      reset();
      isXLDesktop = true;
    }

    if (window.innerWidth < 1280 && isXLDesktop) {
      reset();
      isXLDesktop = false;
    }
  });

  if ($backToResultProduct) {
    scrollToProduct($backToResultProduct);
  } else {
    setTimeout(() => {
      reset();
    }, 0);
  }
};

const throttleScroll = () =>
  throttle(loadVisibleCompositionEstimates, 250, { leading: false, trailing: true });

const initEvents = () => {
  listenForColorSelect(event => {
    const link = event.target.closest(".product-color-picker__item");
    if (link) {
      sendDataLayerOnLinkClickEvent(
        event,
        {
          genericEvent: true,
          eventCategory: "Product card color picker",
          event: "Color click",
          eventLabel: null,
          eventValue: 1
        },
        link
      );
    }
  });

  Array.from(document.querySelectorAll(".js-see-all-link")).forEach(e => {
    e.addEventListener("click", () => {
      sendDataLayer({
        genericEvent: true,
        eventCategory: "See All",
        event: "Click",
        eventLabel: "Link",
        eventValue: 1
      });
    });
  });

  EventBus.on(Events.COLOR_PICKER_OPENED, () => {
    sendDataLayer({
      genericEvent: true,
      eventCategory: "Product card color picker",
      event: "Open",
      eventLabel: null,
      eventValue: 1
    });
  });

  EventBus.on(Events.COLOR_PICKER_CLOSED, () => {
    sendDataLayer({
      genericEvent: true,
      eventCategory: "Product card color picker",
      event: "Close",
      eventLabel: null,
      eventValue: 1
    });
  });

  window.addEventListener("scroll", throttleScroll(), { passive: true });
};

const publishRequestRating = () => {
  const $products = document.querySelector(".js-products");
  if ($products && $products.dataset.requestMobileAppRating === "true") {
    mobileApp.mq.publish("askForRating");
  }
};

export default () => {
  Array.from(document.querySelectorAll(".toggle-bulk-discount-table")).forEach(e => {
    e.addEventListener("click", () => {
      Array.from(document.querySelectorAll(".bulk-discount-table")).forEach(el => {
        el.classList.toggle("hide");
      });
    });
  });

  initColorPicker();
  initReferFromProductDetails();
  initProductMosaic();
  initProductsReviews();
  initEvents();
  initScroll();
  initProductCards();
  initTopFilter();
  loadFirstFewRowsEstimates();
  initProductsPrimaryMaterialFilter();
  initProductsMobileCarousel({
    scrollCallback: throttleScroll()
  });
  publishRequestRating();
  initDtfCategoryFilter();
  digitalAssetsAssetCard();
  digitalAssetsImagesPreview().init();
};
